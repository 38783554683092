<template>
<div>
  <section>
    <el-alert class="mb-2" title="Default Account form AR = 13622500, AP = 22182250, if not set" type="success" :closable="false"></el-alert>
    <AdvanceTable ref="advanceTable" url="erp/warehouse-code-mapping" :height="window.height - 310" tableSize="sm" :columns="columns" :baseFilters="{isShowDeleted: isShowDeleted}" v-loading="isLoading" @edit="openModalForEdit">
      <template #button>
        <el-switch
          v-model="isShowDeleted"
          active-text="Show Deleted"
          inactive-text=""
          class="mr-1" @change="reload()">
        </el-switch>

        <el-button type="primary" size="small" class="mr-1" @click="openModalForCreate" v-if="getErpCanWriteGlobal()">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">{{ $t("Warehouse Code Mapping") }}</span>
        </el-button>
      </template>
      <template #cell(actions)="row">
        <el-link icon="el-icon-edit" @click="openModalForEdit(row.item)" v-if="getErpCanWriteGlobal()">{{$t('Edit')}}</el-link>
        <el-link icon="el-icon-delete" @click="openModalForDelete(row.item)" v-if="getErpCanWriteGlobal()">{{$t('Delete')}}</el-link>
        <el-link icon="el-icon-search" @click="showAudits(row.item)">{{$t('Audits')}}</el-link>
      </template>
    </AdvanceTable>
  </section>

  <el-dialog
    class="compact"
    :title="$t(title)"
    :visible.sync="isShow"
  >
    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Warehouse Code')">
          <el-input v-model="row.warehouseCode" ref="input"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Account Code (AR) - C')">
          <el-input v-model="row.accountCodeArCredit" ref="input"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Account Code (AR) - D')">
          <el-input v-model="row.accountCodeArDebit" ref="input"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Account Code (AP-Labour/Truck) - C')">
          <el-input v-model="row.accountCodeApGroupACredit" ref="input"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Account Code (AP-Labour/Truck) - D')">
          <el-input v-model="row.accountCodeApGroupADebit" ref="input"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Account Code (AP-Storage/Others/Warehouse/Freight/Lastmile) - C')">
          <el-input v-model="row.accountCodeApGroupBCredit" ref="input"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Account Code (AP-Storage/Others/Warehouse/Freight/Lastmile) - D')">
          <el-input v-model="row.accountCodeApGroupBDebit" ref="input"/>
        </b-form-group>
      </b-col>
    </b-row>

    <div>
      <div class="w-100 d-flex justify-content-end">
        <b-button variant="info" class="btn mr-2" @click="isShow=false;$refs.advanceTable.loadList()">
          {{ $t('Cancel') }}
        </b-button>
        <b-button variant="primary" class="btn" @click="save()">
          {{ $t('Save') }}
        </b-button>
      </div>
    </div>
  </el-dialog>

  <AuditTrailModal ref="auditTrail"/>
  </div>
</template>

<script>
import { getErpCanWriteGlobal, getErpProfile } from "@/utils/account-localstorage";


import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import apis from "@/configs/apis";
import { BModal } from "bootstrap-vue";
import Prompt from "@/views/erp/mixins/Prompt";
import AuditTrailModal from "@/views/erp/components/AuditTrailModal.vue";
import axios from "axios";
import _ from "lodash";

export default {
  mixins: [Prompt],
  components: {
    AuditTrailModal,
    BModal,
    AdvanceTable,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      columns: [
        { key: "id", modelName: "id", label: "ID", width: "80", filtertype: "input", sortable: true },
        { key: "warehouseCode", modelName: "warehouseCode", label: "Warehouse Code", width: "120", filtertype: "input", sortable: true },
        { key: "accountCodeArCredit", modelName: "accountCodeArCredit", label: "A/C (AR)-C", filtertype: "input", sortable: true },
        { key: "accountCodeArDebit", modelName: "accountCodeArDebit", label: "A/C (AR)-D", filtertype: "input", sortable: true },
        { key: "accountCodeApGroupACredit", modelName: "accountCodeApGroupACredit", label: "A/C (AP-Labour/Truck)-C", filtertype: "input", sortable: true },
        { key: "accountCodeApGroupADebit", modelName: "accountCodeApGroupADebit", label: "A/C (AP-Labour/Truck)-D", filtertype: "input", sortable: true },
        { key: "accountCodeApGroupBCredit", modelName: "accountCodeApGroupBCredit", label: "A/C (AP-Non-Labour/Truck)-C", filtertype: "input", sortable: true },
        { key: "accountCodeApGroupBDebit", modelName: "accountCodeApGroupBDebit", label: "A/C (AP-Non-Labour/Truck)-D", filtertype: "input", sortable: true },
        { key: "actions", modelName: "actions", label: "Actions", sortable: false, width: "240" },
      ],
      productList: [],
      isShow: false,
      isShowTest: false,
      testEmail: '',
      title: '',
      row: {},
      isShowDeleted: true,
      isLoading: false,
    };
  },
  computed: {
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {

  },
  methods: {
    getErpCanWriteGlobal,
    getErpProfile,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    reload() {
      this.$nextTick(() => {
        this.$refs.advanceTable.loadList();
      });
    },
    openModalForView(item) {
      this.$alert(item.content, 'Title: ' + item.title);
    },
    openModalForCreate() {
      Object.keys(this.row).forEach(key => (this.row[key] = ''));
      this.isShow = true
      this.action = 'CREATE'
      this.title = 'Add Warehouse Code Mapping'
    },
    openModalForEdit(item) {
      this.row = _.clone(item);
      this.isShow = true
      this.action = 'EDIT'
      this.title = 'Edit Warehouse Code Mapping'
    },
    openModalForDelete(item) {
      this.$confirm('Confirm delete this item?', 'Delete Item: ' + item.id).then(() => {
        const response = axios.delete(
          apis.erpWarehouseCodeMappings + "/" + item.id,
        ).then(response => {
          this.reload();
          this.promptInfo("Item Deleted");
        }).catch(e => {
          this.promptError(e);
        })
      }).catch(() => {});
    },
    async save(){
      if (this.action === 'CREATE') {
        try {
          const response = await axios.post(apis.erpWarehouseCodeMappings, this.row);
          this.reload();
          this.isShow = false

          this.promptInfo('Create Warehouse Code Mapping Success');
        } catch (e) {
          this.promptError(e);
        }
      }else{
        try {
          const response = await axios.put(apis.erpWarehouseCodeMappings + '/' + this.row.id, this.row);
          this.reload();
          this.isShow = false

          this.promptInfo('Update Warehouse Code Mapping Success');
        } catch (e) {
          this.promptError(e);
        }
      }
    },

    showAudits(entity) {
      this.$refs.auditTrail.show({objectId: entity.id, className: 'com.kerrylogistics.dashboard.entities.erp.WarehouseCodeMapping', name: 'Warehouse Code Mapping'});
    }
  }
};
</script>
